// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
@import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}

/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';


/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-slideshow';
@import 'modules/module-header';
@import 'modules/module-soumission';



/*******************************************************************************
* ACCUEIL
*******************************************************************************/

#blocIntro-burst {
    background: #000;
    padding: 0 2% 75px 2%;

    .section-burst {
        display: flex;
        justify-content: space-around;
        padding: 50px 5%;
        position: relative;
        margin-top: -95px;
        z-index: 100;

        .burst1 {
            background: #2d67b2;
        }
        .burst2 {
            background: #2b4fa0;
        }
        .burst3 {
            background: #293189;
        }

        .burst {
            width: 32%;
            transition: .5s;
            a {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            height: calc(100vw * 250 / 1920);
            p {
                text-align: center;
                font-weight: 700;
                font-size: $font-size-24;
                line-height: 1.3;
                color: #fff;
                text-transform: uppercase;
            }
            &:hover {
                transform: scale(1.2);
                transition: .5s;
            }
        }
    }

    .blocIntro {
        text-align: center;
        padding-top: 25px;

        h3 {
            font-weight: 700;
            color: #fff;
            font-size: $font-size-36;
            padding-bottom: 5px;
        }
        p {
            font-weight: normal;
            color: #fff;
            font-size: $font-size-24;
            line-height: 2;
            margin: 0;
        }
    }
}

#section-finance {
    padding: 75px 5% 25px 5%;
    h3 {
        font-weight: 700;
        font-size: $font-size-30;
        color: #262523;
        padding-bottom: 25px;
        text-align: center;
    }
    .img-responsive {
        width: 30%;
        margin: auto;
    }
    @media screen and (max-width: $size-sm-max){
        .img-responsive {
            width: 45%;
        }
        @media screen and (max-width: $size-xs-max){
            .img-responsive {
                width: 75%;
            }
        }
    }
}

#section-logo {
    padding: 75px 5%;
    @media screen and (max-width: 1024px) {
        flex-wrap: wrap;
        .logo {
            width: 30%;
            margin: auto;
            padding: 15px;
            .img-responsive {
                margin: auto;
            }
        }
        @media screen and (max-width: $size-xs-max){
            .logo {
                width: 50%;
                padding: 10px;
            }
        }
    }
}

#bloc50-50 {
    display: flex;
    margin-left: -1px;

    .left-side, .right-side {
        width: 50%;
    }

    .main-content {
        position: relative;

        .owl-theme {
            .custom-nav {
                position: absolute;
                top: 20%;
                left: 0;
                right: 0;

                .owl-prev, .owl-next {
                    position: absolute;
                    height: 100px;
                    color: inherit;
                    background: none;
                    border: none;
                    z-index: 100;

                    i {
                        font-size: 2.5rem;
                        color: #fff;
                    }
                }
                .owl-prev {
                    left: 0;
                    top: 165px;
                }
                .owl-next {
                    right: 0;
                    top: 165px;
                }
            }
        }
    }

    .right-side {
        background: #e9e9e9;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2%;
        li {
            text-transform: initial;
            font-weight: normal;
            font-size: $font-size-30;
            margin-bottom: -10px;
            list-style-type: square;
        }
    }
}

/*******************************************************************************
* PAGE TOITURE BARDEAU
*******************************************************************************/

section.section-02-bg {
    background-image: url('../images/bardeau_section01_bg.jpg');
    background-position: center;
    background-size: cover;
    padding: 120px 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    div.text-wrapper {
        max-width: 1170px;
        h3 {
            text-align: center;
            color: $color-white;
            font-size: $font-size-30;
            line-height: 1.3;
        }
    }
}

section.section-text-image {
    display: flex;
    align-items: stretch;
    justify-content: center;

    & > div {
        width: 50%;
    }
    div.image-side {
        min-height: 400px;
        background-image: url();
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    div.text-side {
        display: flex;
        justify-content: center;
        align-items: center;
        div.text-wrapper {
            margin: 1rem;
        }
    }

    li {
        font-size: $font-size-24;
        font-weight: 400;
        text-transform: inherit;
    }

    @media screen and (max-width: $size-xs-max){
        flex-direction: column;
        & > div {
            width: 100%;
        }
    }
}

section.logo-section {
    padding: 30px 15px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    div.item {
        margin: 20px;
    }
}

/*******************************************************************************
* PAGE TOITS PLATS
*******************************************************************************/

div.services-toits-plats {
    padding: 30px 15px;
    div.item {
        max-width: 1600px;
        display: flex;
        margin: 50px 0!important;
        margin: auto;
        align-items: flex-start;

        div.text-box {
            flex: 1;
            padding-right: 20px;
        }
    }

    h3 {
        color: $color-white;
        font-size: $font-size-30;
        padding-bottom: 30px;
    }
    h4 {
        color: #2d67b2;
        text-transform: uppercase;
        font-size: $font-size-24;
        padding-bottom: 20px;
    }
    p {
        color: $color-white;
        font-weight: 400;
        text-align: justify;
    }
    @media screen and (max-width: $size-sm-max){
        div.item {
            div.text-box, div.image {
                width: 50%;
            }
        }
        @media screen and (max-width: $size-xs-max){
          div.item {
              flex-direction: column;
              div.text-box, div.image {
                  width: 100%;
                  text-align: center;
                  img {margin: 20px auto;}
              }
          }
        }
    }
}


/*******************************************************************************
* PAGE REALISATIONS
*******************************************************************************/

section.realisations-section {
    padding: 30px 15px;
    div.container-fluid {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        margin: 0;
        align-items: flex-start;
        justify-content: center;

        & > * {
            max-width: 30%;
            margin: 15px;
            min-width: 250px;
        }
    }

    .item {
        div.img-box {
            overflow: hidden;
            img {
                transition: .3s;
            }
        }
        div.text-box {
            h4 {
                color: #2d67b2;
                padding: 15px 0;
                text-transform: uppercase;
                font-size: $font-size-24;
            }
            p {
                color: $color-black;
                font-weight: 400;
                line-height: 1.5;
            }
        }
        &:hover {
            div.img-box img {
                transform: scale(1.2);
                transition: .3s;
            }
        }
    }

}


///gallery popup
.image-source-link {
  color: #98C3D1;
}

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
opacity: 0;
-webkit-backface-visibility: hidden;
/* ideally, transition speed should match zoom duration */
-webkit-transition: all 0.3s ease-out;
-moz-transition: all 0.3s ease-out;
-o-transition: all 0.3s ease-out;
transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
  opacity: 1;
}
.mfp-with-zoom.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
opacity: 0;
}


/******************************************************************************/
/**PAGE COORDONNEES
/******************************************************************************/

section.section-coordonnees {
    padding: 30px 0;
    .container-fluid.row {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        & > div {
            width: 50%;
            transition: .5s;
            @media screen and (max-width: $size-xs-max){
                width: 100%;
                transition: .5s;
            }
        }
    }

    div.left-side {
        display: flex;
        align-items: center;
        justify-content: center;

        h3 {
            font-size: $font-size-30;
            color: $color-white;
        }
        h4 {
            color: #2d67b2;
            font-weight: 400;
            padding: 50px 0 25px 0;
            font-size: $font-size-26;
            text-transform: uppercase;
        }
        p {
            color: $color-white;
            font-weight: 400;
            display: flex;
            justify-content: space-between;
            max-width: 300px;
        }
        .tel-courriel {
            padding-top: 25px;
            a {
                font-size: $font-size-30;
                color: $color-white;
                &:hover {
                    color: #2b4fa0;
                }
            }
        }
    }

    #bodyContent p a{
        color: #2d67b2;
        &:hover {color: $color-black;}
    }
}

/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: 1720px) {

    nav.module-menu .menu-navbar .wrapper.right .logo {
        clip-path: polygon(0 0, 100% 0%, 350px 100%, 0% 100%);
        padding: 15px 5% 15px 30px;
    }
    nav.module-menu .menu-navbar .wrapper.right .menu-tel {
        clip-path: polygon(90px 0, 100% 0%, 100% 100%, 0% 100%);
        padding: 20px 25px 10px 5%;
    }
    #bloc50-50 .right-side li {
        margin-bottom: 30px;
        line-height: 1.3;
    }
}

@media screen and (max-width: 1570px) {

    nav.module-menu .menu-navbar .wrapper.right .logo {
        clip-path: polygon(0 0, 100% 0%, 330px 100%, 0% 100%);
        padding: 15px 3% 15px 30px;
    }
}

@media screen and (max-width: 1500px) {

    nav.module-menu .menu-navbar .wrapper.right .logo {
        clip-path: polygon(0 0, 100% 0%, 215px 100%, 0% 100%);
        padding: 15px 3% 15px 30px;
        max-width: 250px;
        height: 110px;
    }
    nav.module-menu .menu-navbar .wrapper.right .menu-tel {
        clip-path: polygon(41px 0, 100% 0%, 100% 100%, 0% 100%);
        padding: 20px 25px 10px 2%;
        max-width: 250px;
        height: 110px;
    }
    nav.module-menu .menu-navbar .wrapper.right .menu-tel .phone div.phonenumber {
        font-size: 1.2rem;
    }
    nav.module-menu .menu-navbar .wrapper.right .menu-tel p.rbq {
        font-size: 1rem;
    }
}

@media screen and (max-width: 1460px) {

    #bloc50-50 .right-side li {
        font-size: 1.3rem;
    }
}

@media screen and (max-width: 1335px) {

    #bloc50-50 .right-side li {
        font-size: 1.2rem;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: $size-md-max) {

    nav.module-menu .menu-navbar {
        height: 115px;
    }
    nav.module-menu .menu-navbar.nav-invisible {
        top: -130px;
    }
    nav.module-menu {
        padding-top: 113px;
    }
    #bloc50-50 .main-content .owl-theme .custom-nav .owl-prev, #bloc50-50 .main-content .owl-theme .custom-nav .owl-next {
        top: 100px;
    }
}

@media screen and (max-width: 1200px) {

    nav.module-menu .menu-navbar .wrapper.right .menu-tel {
        display: none;
    }
    nav.module-menu .menu-navbar .wrapper.right {
        background: #2d5dab;
    }
    nav.module-menu .menu-navbar .wrapper.right .logo {
        top: 0;
        max-width: 300px;
        height: 115px;
        clip-path: polygon(0 0, 100% 0%, 285px 100%, 0% 100%);
    }
    nav.module-menu .menu-toggle .word {
        font-size: 1.3rem;
        padding: 5px 0;
    }
    .menu-mobile .menu-tel ul p.rbq {
        text-align: center;
    }
    nav.module-menu {
        padding-top: 115px;
    }
    nav.module-menu .menu-navbar.nav-invisible {
        top: -120px;
    }
}

@media screen and (max-width: 1024px) {

    #bloc50-50 .right-side li {
        margin-bottom: 15px;
    }
    #bloc50-50 .main-content .owl-theme .custom-nav .owl-next, #bloc50-50 .main-content .owl-theme .custom-nav .owl-prev {
        top: 90px;
    }
}

@media screen and (max-width: $size-sm-max) {

    #bloc50-50 {
        flex-direction: column;
    }
    #bloc50-50 .left-side, #bloc50-50 .right-side {
        width: 100%;
    }
    #bloc50-50 .right-side {
        //height: 575px;
    }
    #bloc50-50 .right-side li {
        margin-bottom: 20px;
    }
    #blocIntro-burst .section-burst .burst p {
        font-size: 1.3rem;
    }
    #bloc50-50 .main-content .owl-theme .custom-nav .owl-next, #bloc50-50 .main-content .owl-theme .custom-nav .owl-prev {
        top: 150px;
    }
    #bloc50-50 .right-side {
        padding: 5% 2% 2% 2%;
    }
}

@media screen and (max-width: $size-xs-max) {

    #bloc50-50 .right-side {
        //height: 310px;
    }
    #bloc50-50 .main-content .owl-theme .custom-nav .owl-next, #bloc50-50 .main-content .owl-theme .custom-nav .owl-prev {
        top: 70px;
    }
    #blocIntro-burst .section-burst {
        flex-direction: column;
    }
    #blocIntro-burst .section-burst .burst {
        width: 100%;
        height: calc(250vw * 250 / 1920);
        margin-bottom: 15px;
    }
    #blocIntro-burst .section-burst .burst p {
        font-size: 1.5rem;
    }
    nav.module-menu .menu-navbar .wrapper.right .logo {
        max-width: 175px;
        clip-path: polygon(0 0, 100% 0%, 165px 100%, 0% 100%);
        padding: 15px 3% 15px 3%;
    }
    nav.module-menu .menu-toggle .word {
        font-size: 1.2rem;
    }
    #bloc50-50 .right-side {
        padding: 9% 2% 2% 2%;
    }
}
