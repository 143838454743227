.module-header {
    position: relative;

    &--image {}

    &--image-mobile {}

    &--caption {
        position: absolute;
        top: 50%;
        width: 100%;
        text-align: center;
        transform: translateY(-50%);

        &--title {
            width: 100%;
            text-align: center;
            font-size: $font-size-50;
            font-weight: 700;
            color: $color-text-contrast;
            text-shadow: 0 0 10px $color-black;
        }
        &--subtitle {
            width: 100%;
            text-align: center;
            font-size: 3vw;
            font-weight: 300;
            color: $color-1;
            text-shadow: 0 0 10px $color-black;
        }

    }
}
@media screen and (max-width: $size-md-max) {
    .module-header {
        &--caption {
            &--title {
                font-size: 6vw;
            }
            &--subtitle {
                font-size: 4vw;
            }
        }
    }
}
@media screen and (max-width: $size-sm-max) {
    .module-header {
        &--caption {
            &--title {
                font-size: 7vw;
            }
            &--subtitle {
                font-size: 5vw;
            }
        }
    }
}
@media screen and (max-width: $size-xs-max) {
    .module-header {
        &--caption {
            &--title {
                font-size: 8vw;
            }
            &--subtitle {
                font-size: 6vw;
            }
        }
    }
}
