
/** to add hover effect on icon **/
.filter-hover {filter: invert(38%) sepia(12%) saturate(4129%) hue-rotate(180deg) brightness(91%) contrast(91%);}
.filter-black {filter: invert(0%) sepia(94%) saturate(10%) hue-rotate(288deg) brightness(107%) contrast(95%);}
.filter-normal {filter: invert(100%) sepia(49%) saturate(1955%) hue-rotate(277deg) brightness(142%) contrast(100%);}

.icon {
    a {
        background-color: inherit;
        position: relative;
        .overlay {
            width: 100%;
            height: 100%;
            opacity: 0;
            visibility: hidden;
            position: absolute;
            top:0;
            background-color: $bg-color-nav-highlight;
            mix-blend-mode: multiply;
            transition: all 0.2s ease-in;
            // for IE explorer fallback
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                background: transparentize($color-black, 0.5);
            }
            // For Edge fallback
            @supports (-ms-ime-align:auto) {
                background: transparentize($color-black, 0.5);
            }
        }
        &:hover {
            .overlay {
                opacity: 1;
                visibility: visible;
                transition: all 0.2s ease-out;
            }
        }
    }
}